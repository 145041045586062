// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Theme$GlazedWebsite from "../../Theme.js";
import * as Gatsby$GlazedWebsite from "../../shared/Gatsby.js";
import * as Heading$GlazedWebsite from "../../Heading.js";
import * as CaseStudyPageTemplate$GlazedWebsite from "./building-blocks/CaseStudyPageTemplate.js";
import * as LogoGlazedSmallSvg from "static/images/logo-glazed-small.svg";

var inPageHeading = Curry._1(Css.style, /* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.maxWidth(Css.rem(64)),
        /* :: */[
          Css.padding2(/* zero */-789508312, Css.rem(1.875)),
          /* :: */[
            Css.margin(/* auto */-1065951377),
            /* :: */[
              Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  inPageHeading: inPageHeading
};

var pageImagesQuery = (Gatsby.graphql`
     query {
       headerImage: file(relativePath: { eq: "case-studies/lvmh-hero.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       bigImage: file(relativePath: { eq: "case-studies/lvmh-02.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       productImage: file(relativePath: { eq: "case-studies/lvmh-01.png" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       productImage2: file(relativePath: { eq: "case-studies/lvmh-03.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       nextCaseImage: file(relativePath: { eq: "case-studies/farfetch-hero.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200, maxHeight: 820) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
     }
  `);

function LVMH(Props) {
  var queryResult = Gatsby.useStaticQuery(pageImagesQuery);
  var headerImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "headerImage");
  var bigImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "bigImage");
  var productImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "productImage");
  var productImage2 = Gatsby$GlazedWebsite.getImageFluid(queryResult, "productImage2");
  var nextCaseImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "nextCaseImage");
  var content_hero = {
    image: headerImage,
    title: "LVMH",
    area: "Fashion - Chatbots",
    text: "Moët Hennessy Louis Vuitton is a French multinational corporation and conglomerate specializing in luxury goods."
  };
  var content_brief = {
    techs: [
      {
        name: "Google Assistant",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "Alexa Skills",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "IBM Watson Assistant",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "Dialogflow",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "Elixir",
        icon: LogoGlazedSmallSvg.default
      }
    ],
    year: "2016 - 2020",
    brief: "Develop a conversational solution to engage and create personalised experiences for customers."
  };
  var content_content = [
    /* BigImage */Block.__(0, [productImage]),
    /* TextAndImage */Block.__(1, [
        "We wanted to hit the floor running, as such, we looked for the best third party services that would be able to aid us with NLU (Natural Language Understanding) and dialogue handling to provide customers with valuable, engaging and bespoke experiences.\n\n        At the time, Facebook Messenger was the most used and complete service, and since we wanted to validate our design approach this was a good option to develop our first chatbots. As we learned more about the technology and the requirements evolved, we ended up building our own dialogue engine.\n\n        Ownership of the dialogue engine allowed us to extend it to fully support live chat with the seamless human handover, including companion features, quickly and effectively without compromising on our UX, which would otherwise have been impossible. Plus, we developed tools to facilitate NLU training, reusability, deployment and become service agnostic.        ",
        bigImage
      ]),
    /* Custom */Block.__(5, [React.createElement(Heading$GlazedWebsite.make, {
              level: /* H3 */2,
              className: inPageHeading,
              children: "The project’s NLU understanding was constantly improving from an always growing shared pool of examples obtained from training and also real chats."
            })]),
    /* TextAndImage */Block.__(1, [
        " Focusing more on service use cases we started handling user data gathering and GDPR compliance, improving flows to act as the first line of customer triage and also to cope with customer centres out of hours schedules.\n\n        Over time we’ve grown our channel support to Facebook Messenger, WhatsApp, WeChat, Google Assistant, Alexa, Web Pages (chat widget) and Slack.\n\n        We also integrated with multiple third-party services like Salesforce, added human handover with live chat, booking appointments and even image recognition based product recommendation services.",
        productImage2
      ]),
    /* BigVideo */Block.__(3, ["https://player.vimeo.com/video/455530260?autoplay=1&muted=1&loop=1"])
  ];
  var content_nextCase = {
    image: nextCaseImage,
    title: "Farfetch",
    area: "Fashion - Ecommerce",
    link: "/case-studies/farfetch"
  };
  var content = {
    hero: content_hero,
    brief: content_brief,
    content: content_content,
    nextCase: content_nextCase
  };
  return React.createElement(CaseStudyPageTemplate$GlazedWebsite.make, {
              content: content,
              pageTitle: "Glazed • LVMH",
              pageDescription: "Moët Hennessy Louis Vuitton is a French multinational corporation and conglomerate specializing in luxury goods."
            });
}

var make = LVMH;

var $$default = LVMH;

export {
  Styles ,
  pageImagesQuery ,
  make ,
  $$default ,
  $$default as default,
  
}
/* inPageHeading Not a pure module */
